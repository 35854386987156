import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/itemGroup4'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  getAll () {
    return httpClient.get(`${endpoint}/all`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getItemGroup4ById (idItemGroup3) {
    return httpClient.get(`${endpoint}/getByIdGroup3/${idItemGroup3}`)
  }
}
